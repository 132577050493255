<template>
  <span>{{$moment.duration(time, 'seconds').minutes() }}:{{ $moment.duration(time, 'seconds').seconds() }} min</span>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      required: true,
    },
  },
  watch: {
    time() {
      setTimeout(() => {
        this.updateTime();
      }, 1000);
    },
    number(e) {
      this.time = e;
    },
  },
  methods: {
    updateTime() {
      this.time = this.time + 1;
    },
  },
  data: () => ({
    time: 0,
  }),
  mounted() {
    this.time = this.number;
    this.updateTime();
  },
};
</script>

<style>

</style>
